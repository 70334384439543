import React, { useRef,Fragment } from 'react'
import Input from '../components/Input'
import { Form } from '@unform/web';
import { Utils } from '../utils'
import { request } from '../services/request'
import { useHistory } from 'react-router-dom';
import FooterLogin from "../components/FooterLogin"
import NavBar from "../components/Navbar"
import {Row,Col} from "react-bootstrap"
import logo from '../assets/img/icon-pes.png'

import "../assets/css/login.css"

export default function Logon() {
    const history = useHistory();
    const loginFormRef = useRef(null);
    const handleSubmit = async () => {
        let data = loginFormRef.current.getData()
        data = Utils.changeEmptyValueToNull(data)

        const res = await request({
            method: "POST",
            path: "user/login",
            data: data,
            showSuccessMessage: false
        })

        if (!res.error) {
            localStorage.setItem('crm_saude_token', res.token)
            history.push('/')
        }
    }

    return (
        <Fragment>
            <NavBar />
            <div >
                <Col className="col-main" md="4"  >
                    <img className="logo" src={logo} alt="Logo" />
                    <h3 className="text-center title">Bem-vindo</h3>
                    <p className="text-center" style={{fontSize:"0.938rem",color:"#475561"}}>Desejamos um bom dia de trabalho!</p>
                    <Form className=" form-main" ref={loginFormRef} onSubmit={handleSubmit}>
                        <Input
                            className=" form-control conect"
                            name="username"
                            type="text"
                            placeholder="Login"
                        />

                        <Input
                            placeholder="Senha"
                            name="password"
                            type="password"
                            className="form-control password"
                        />

                        <button type="submit" className="button button-large button-login" >Logar</button>

                    </Form>
                </Col>
            </div>
            <FooterLogin />
        </Fragment>

        
    )
}
