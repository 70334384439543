import React, { useEffect, useState } from "react";
import { request } from "../../services/request";
import Alert from "../../components/Alert";
import "../../assets/css/result.css";

export default function FullQuestionnaire({
  patient,
  result,
  diagnosisId,
  callbackSaveReccomendation,
}) {
  const [resultMessage, setResultMessage] = useState();
  const [options, setOptions] = useState([]);
  const [optionsForPatient, setOptionsForPatient] = useState([]);
  const [dataToSave, setDataToSave] = useState({
    recommendationExplained: null,
    agentReccomendation: null,
    pacientStart: null,
    platformReccomendation: null,
  });
  const [showAttendentResponse, setShowAttendentResponse] = useState(false);
  const [responseResult, setResponseResult] = useState({});
  const [serious, setSerious] = useState([]);

  const getResult = async () => {
    const res = await request({
      method: "GET",
      path: `/diagnosis/${diagnosisId}/result`,
      showSuccessMessage: false,
      showErrorMessage: false,
    });
    if (!res.error) {
      setResponseResult(res);
      setResultMessage(res.result.triageMessage);
      setDataToSave({
        platformReccomendation: res.result.triage_level,
        agentReccomendation: res.result.triage_level,
      });

      const optionsKeys = Object.keys(res.options);
      setOptions(
        optionsKeys.map((opt) => {
          return {
            id: opt,
            label: res.options[opt],
          };
        })
      );

      const optionsKeysPatient = Object.keys(res.resultOptions);
      setOptionsForPatient(
        optionsKeysPatient.map((opt) => {
          return {
            id: opt,
            label: res.resultOptions[opt],
          };
        })
      );

      setSerious(res.result.serious);
    }
  };

  const handleRecommendationChange = () => {
    const recommendation = document.querySelector(
      "[name=recommendation]:checked"
    ).value;
    setDataToSave({
      ...dataToSave,
      agentReccomendation: recommendation,
    });
    if (recommendation !== responseResult.result.triage_level) {
      setShowAttendentResponse(true);
    } else {
      setDataToSave({
        ...dataToSave,
        recommendationExplained: null,
      });
      setShowAttendentResponse(false);
    }
  };

  const handlePacientStart = () => {
    const pacientStart = document.querySelector(
      "[name=pacientStart]:checked"
    ).value;
    setDataToSave({
      ...dataToSave,
      pacientStart,
    });
  };

  const handleRecommendationExplained = () => {
    setDataToSave({
      ...dataToSave,
      recommendationExplained: document.querySelector(
        "[name=recommendationExplained]"
      ).value,
    });
  };

  const handleSave = async () => {
    if (showAttendentResponse) {
      if (!dataToSave.recommendationExplained) {
        Alert.error(
          "Explique o motivo da sua decisão ser diferente da dada pela plataforma"
        );
        return;
      }
    }

    if (!dataToSave.pacientStart) {
      Alert.error(`Selecione "O paciente chegou querendo?"`);
      return;
    }
    const res = await request({
      method: "POST",
      path: `/diagnosis/${diagnosisId}/save-recommendation`,
      showSuccessMessage: false,
      data: dataToSave,
    });

    if (!res.error) {
      await Alert.withTimeout("Recomendação salva com sucesso", 2000);
      callbackSaveReccomendation();
    }
  };

  useEffect(() => {
    getResult();
  }, [diagnosisId]); //eslint-disable-line

  return (
    <div className="container-result col-md-12">
      <div
        className="col-md-6"
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
      >
        <h1 className="text-options-possible">Recomendação da plataforma</h1>
        <p>{resultMessage}</p>
      </div>
      <hr />

      <div
        className="col-md-6"
        style={{ marginBottom: "2rem", marginTop: "2rem" }}
      >
        <h1 className="text-options-possible">Possíveis opções: </h1>
        {result.map((r) => {
          return (
            <div key={r.id}>
              <label htmlFor={r.id}>{r.common_name}</label>
              <progress
                style={{ marginLeft: "1rem" }}
                id={r.id}
                value={r.probability * 100}
                max="100"
              >
                {" "}
                {r.probability * 100}%{" "}
              </progress>
            </div>
          );
        })}
      </div>

      <hr />
      {serious.length > 0 && (
        <div style={{ marginBottom: "2rem", marginTop: "2rem" }}>
          <h1 className="text-options-possible">Riscos sérios</h1>
          {serious.map((s) => {
            return <p key={s.id}>{s.common_name}</p>;
          })}
        </div>
      )}
      <hr />
      <div style={{ marginBottom: "2rem", marginTop: "2rem" }}>
        <h1 className="text-options-possible">
          Recomendação da(o) enfermeira(o):{" "}
        </h1>
        {options.map((option) => {
          let checked = option.id === responseResult.result.triage_level;
          return (
            <div key={option.id} style={{ width: "100%", float: "left" }}>
              <label htmlFor={option.id}>
                <input
                  type="radio"
                  name="recommendation"
                  defaultChecked={checked}
                  value={option.id}
                  onChange={handleRecommendationChange}
                />
                {option.label}
              </label>
            </div>
          );
        })}
        {showAttendentResponse && (
          <>
            <label htmlFor="recommendationExplained">
              Nos diga o motivo da recomendação ser diferente da recomendação da
              plataforma
            </label>
            <textarea
              name="recommendationExplained"
              id="recommendationExplained"
              onChange={handleRecommendationExplained}
            />
          </>
        )}
      </div>
      <hr />
      <div style={{ marginBottom: "2rem", marginTop: "2rem" }}>
        <h1 className="text-options-possible">O paciente chegou querendo? </h1>
        {optionsForPatient.map((option) => {
          return (
            <div key={option.id} style={{ width: "100%", float: "left" }}>
              <label style={{ marginLeft: "0.5rem" }} htmlFor={option.id}>
                <input
                  type="radio"
                  name="pacientStart"
                  value={option.id}
                  onChange={handlePacientStart}
                />
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
      <button
        style={{ marginTop: "2rem" }}
        className="button button-large button-cpf"
        type="button"
        onClick={handleSave}
      >
        Salvar
      </button>
    </div>
  );
}
