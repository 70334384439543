import React, { useRef, useState, useEffect } from "react";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import { Form } from "@unform/web";
import { Utils } from "../../utils";
import { request } from "../../services/request";
import Alert from "../../components/Alert";

export default function InitQuestionnairePatient({
  patient,
  goToQuestionsCallBack,
  age,
}) {
  const symptonForm = useRef(null);
  const selectSymptonForm = useRef(null);
  const [symptons, setSymptons] = useState([]);
  const [responseSymptons, setResponseSymptons] = useState([]);
  const handleSyptonsForm = async () => {
    let data = symptonForm.current.getData();
    data = Utils.changeEmptyValueToNull(data);
    data.sex =
      patient.sex === "M" ? "male" : patient.sex === "F" ? "female" : "";
    data.age = age;

    if (!data.sympton) {
      Alert.error("Digite um sintoma");
      return;
    }

    const res = await request({
      method: "POST",
      path: `inital-sypton`,
      data,
      showSuccessMessage: false,
      showFailMessage: false,
    });

    if (!res.error) {
      if (!res.syptons) {
        Alert.error("Tente outro sintoma, ou refine o digitado");
        return;
      }
      setResponseSymptons(
        res.syptons.map((sympton) => {
          sympton.value = sympton.id;
          return sympton;
        })
      );
    }
  };

  const handleMoreSympton = () => {
    let symptonsForm = selectSymptonForm.current;
    if (!symptonsForm) {
      Alert.error("Digite no minímo um sintoma");
      return;
    }
    symptonsForm = symptonsForm.getData().symptons;
    if (!symptonsForm) {
      Alert.error("Selecione no mínimo uma reposta");
      return;
    }
    let allSymptons = symptons;
    symptonsForm.forEach((s) => {
      allSymptons.push(s);
    });
    setSymptons(allSymptons);
    setResponseSymptons([]);
  };

  const goToQuestions = () => {
    console.log(symptons);
    handleMoreSympton();
    if (symptons.length === 0) {
      Alert.error("Pelo menos um sintoma é necessário");
      return;
    }
    goToQuestionsCallBack(
      symptons.map((sympton) => {
        return {
          id: sympton,
          choice_id: "present",
          source: "initial",
        };
      })
    );
  };

  return (
    <>
      {/* <div>
        <h5 className="text-center text-cpf" style={{ marginBottom: "3rem" }}>
          Iniciar diagnóstico
        </h5>
        {responseSymptons.length === 0 && (
          <Form ref={symptonForm} onSubmit={handleSyptonsForm}>
            <div className="col-symptoms">
              <div className="row">
                <label htmlFor="questions">Digite o sintoma</label>
              </div>
              <div className="row">
                <Input
                  className="form-control input-symptoms"
                  placeholder="Ex: dor de barriga"
                  name="sympton"
                />
                <button className="button button-search-symptoms" type="submit">
                  Buscar
                </button>
              </div>
            </div>
          </Form>
        )}

        {responseSymptons.length > 0 && (
          <div className="col-symptoms">
            <Form ref={selectSymptonForm} onSubmit={handleMoreSympton}>
              <Checkbox name="symptons" options={responseSymptons} />

              <button className="button button-add-symptoms" type="submit">
                Adicionar mais sintomas
              </button>
            </Form>
          </div>
        )}
        <hr style={{ marginTop: "3rem", marginBottom: "3rem", width: "80%" }} />
        <button
          type="button"
          className="button button-large button-cpf"
          onClick={goToQuestions}
        >
          Ir para perguntas
        </button>
      </div> */}
    </>
  );
}
