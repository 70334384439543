import React, { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap"
import '../assets/css/navbar.css'
import logo from '../assets/img/icon-pes.png'
import getPayload from "../services/request"
import { CgLogOff } from "react-icons/cg";


export default function NavBar() {
  const [name, setName] = useState(null)

  useEffect(() => {
    if (getPayload()) {
      setName(getPayload().name)
    }
  });


  return (
    <Navbar className="bg-custon" expand="lg">
      <Navbar.Brand href="#home"> <img className="logo-nav" src={logo} alt="Logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {window.location.pathname !== "/login" && (
          <Nav className="mr-auto">
            <Nav.Link className="color-nav-a py-4 active" href="#home">Home</Nav.Link>
          </Nav>
        )}
        {name === undefined ? '' :
          <Nav style={{marginLeft:"auto"}}>
            <Navbar.Brand className="name" >{name}</Navbar.Brand>
            <button className="btn btn-link button-logout" onClick={() => { localStorage.removeItem("crm_saude_token"); window.location.reload() }}>
              <CgLogOff size="1.7rem" />
            </button>
          </Nav>
        }
      </Navbar.Collapse>
    </Navbar>
  )
}