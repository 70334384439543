import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Logon from './pages/Logon'
import Attendance from './pages/attendance'
import getPayload from "./services/request"

const ProtectedRoute = ({ component: Comp,  path, ...rest }) => {
    return (
      <Route
        path={path}
        {...rest}
        render={props => {
          return (getPayload().name!==undefined) ? <Comp {...props} /> : <Redirect to="/login" />;
        }}
      />
    );
  };

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" exact component={Logon} />
                <ProtectedRoute path="/" exact component={Attendance} />
            </Switch>
        </BrowserRouter>
    )
}