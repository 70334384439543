import React from 'react';
import Routes from './routes'
import { Provider } from 'react-redux'
import { Store } from './redux/store'
import Loader from './components/loader'
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/universal.css'

function App() {
  return (
    <Provider store={Store}>
      <Loader></Loader>
      <Routes />
    </Provider>
  )
}

export default App;
