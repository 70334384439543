import React, { useRef, useState, useEffect, useCallback } from "react";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import Radio from "../../components/Radio";
import { Form } from "@unform/web";
import { request } from "../../services/request";

export default function FullQuestionnaire({
  patient,
  initAnswers,
  resultCallback,
}) {
  const [newEvidences, setNewEvidences] = useState([]);
  const [diagnosisId, setDiagnosisId] = useState(null);
  const [questionnaire, setQuestionnaire] = useState({
    question: {
      name: null,
      text: null,
      items: [],
    },
  });
  const [firstQuestionForShowPrevButton, setFirstQuestionForShowPrevButton] =
    useState(false);
  const [alreadyInitAnswersEffect, setAlreadyInitAnswersEffect] =
    useState(false);

  const responseQuestions = useRef(null);

  const nextQuestions = async () => {
    if (!firstQuestionForShowPrevButton && alreadyInitAnswersEffect) {
      setFirstQuestionForShowPrevButton(true);
    }
    const res = await request({
      method: "POST",
      path: `diagnosis/next-questions`,
      data: {
        newEvidences: newEvidences.length > 0 ? newEvidences : initAnswers,
        diagnosisId,
        patient: {
          ...patient,
          sex:
            patient.sex === "F" ? "female" : patient.sex === "M" ? "male" : "",
        },
      },
      showSuccessMessage: false,
      showFailMessage: false,
    });

    if (!res.error) {
      console.log(res.questionnaire.should_stop);
      if (res.questionnaire.should_stop) {
        return resultCallback(res.questionnaire.conditions, res.diagnosisId);
      }
      setDiagnosisId(res.diagnosisId);
      setQuestionnaire(res.questionnaire);
      setNewEvidences([]);
    }
  };

  const handleNextQuestionsButton = () => {
    if (questionnaire.question.type === "group_single") {
      const answer = document.querySelector("[name=answer]:checked").value;
      questionnaire.question.items.forEach((item) => {
        let itemMap = {
          id: item.id,
          choice_id: "absent",
        };
        if (item.id === answer) {
          itemMap.choice_id = "present";
        }
        setNewEvidences((newEvidences) => newEvidences.concat(itemMap));
      });
    }

    if (questionnaire.question.type === "group_multiple") {
      const answers = document.querySelectorAll("[name=answer]:checked");
      for (const answer of answers) {
        setNewEvidences((newEvidences) =>
          newEvidences.concat({
            id: answer.value,
            choice_id: "present",
          })
        );
      }
    }

    if (questionnaire.question.type === "single") {
      const answer = responseQuestions.current.getData();
      let keys = Object.keys(answer);
      let value = answer[keys[0]];

      setNewEvidences((newEvidences) =>
        newEvidences.concat({
          id: keys[0],
          choice_id: value,
        })
      );
    }
  };

  const handlePrevQuestionsButton = async () => {
    const res = await request({
      method: "get",
      path: `diagnosis/${diagnosisId}/prev-questions`,
      data: {
        newEvidences: newEvidences.length > 0 ? newEvidences : initAnswers,
        diagnosisId,
        patient,
      },
      showSuccessMessage: false,
      showFailMessage: false,
    });

    if (!res.error) {
      if (res.questionnaire.should_stop) {
        return resultCallback(res.questionnaire.conditions, res.diagnosisId);
      }
      setDiagnosisId(res.diagnosisId);
      setQuestionnaire(res.questionnaire);
      setNewEvidences([]);
    }
  };

  useEffect(() => {
    nextQuestions();
    setAlreadyInitAnswersEffect(true);
  }, [initAnswers]); //eslint-disable-line

  useEffect(() => {
    if (newEvidences.length > 0) {
      nextQuestions();
    }
  }, [newEvidences]); //eslint-disable-line

  return (
    <>
      <div className="col-symptoms">
        <Form ref={responseQuestions} onSubmit={(e) => {}}>
          {questionnaire.question.type === "group_single" && (
            <>
              <h1 className="text-cpf" style={{ marginBottom: "3rem" }}>
                {questionnaire.question.text}
              </h1>
              {questionnaire.question.items.map((item) => {
                return (
                  <div key={item.id}>
                    <label htmlFor={item.id}>
                      <input
                        type="radio"
                        name="answer"
                        id={item.id}
                        value={item.id}
                      />
                      {item.name}
                    </label>
                  </div>
                );
              })}
            </>
          )}

          {questionnaire.question.type === "group_multiple" && (
            <>
              <h1 className="text-cpf" style={{ marginBottom: "3rem" }}>
                {questionnaire.question.text}
              </h1>
              {questionnaire.question.items.map((item) => {
                return (
                  <div key={item.id}>
                    <label htmlFor={item.id}>
                      <input
                        type="checkbox"
                        name="answer"
                        id={item.id}
                        value={item.id}
                      />
                      {item.name}
                    </label>
                  </div>
                );
              })}
            </>
          )}

          {questionnaire.question.type === "single" && (
            <>
              <h1 className="text-cpf" style={{ marginBottom: "3rem" }}>
                {questionnaire.question.text}
              </h1>

              <Radio
                name={questionnaire.question.items[0].id}
                options={questionnaire.question.items[0].choices}
              />
            </>
          )}
          {firstQuestionForShowPrevButton && (
            <button
              className="button button-remove-symptoms mr-2"
              onClick={handlePrevQuestionsButton}
            >
              pergunta anterior
            </button>
          )}

          <button
            className="button button-add-symptoms"
            onClick={handleNextQuestionsButton}
          >
            Próxima pergunta
          </button>
        </Form>
      </div>
    </>
  );
}
