import axios from "axios";
import Alert from "../components/Alert";
import { Dispatch } from "../redux/store";

export const lifeApiRequest = (params) => {
  const showLoading =
    params.showLoading !== undefined ? params.showLoading : true;
  const loadingMessage =
    params.loadingMessage !== undefined ? params.loadingMessage : "Carregando";
  const timeout = params.timeout ? params.timeout !== undefined : 10000;
  const showSuccessMessage =
    params.showSuccessMessage !== undefined ? params.showSuccessMessage : true;
  const showErrorMessage =
    params.showErrorMessage !== undefined ? params.showErrorMessage : true;
  const method = params.method;
  const path = params.path;
  let data = params.data;

  let showLoadingTimout;
  if (showLoading) {
    showLoadingTimout = setTimeout(function () {
      Dispatch({
        type: "show_loader",
        show: true,
      });
      Dispatch({
        type: "loader_set_message",
        message: loadingMessage,
      });
    }, 1000);
  }

  const base_url = process.env.REACT_APP_BASE_API_URL_LIFE;
  return new Promise(async (resolve, reject) => {
    let config = {
      method: method,
      url: `${base_url}/${path}`,
      timeout: timeout,
    };

    let token = localStorage.getItem("crm_saude_token");
    config.headers = {};
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }

    if (data) {
      config.data = data;
    }

    let project = window.localStorage.getItem("discador_projeto_id");
    if (project) {
      config.headers.ProjectId = JSON.parse(project).value;
    }
    return axios(config)
      .then((response) => {
        clearTimeout(showLoadingTimout);
        Dispatch({
          type: "show_loader",
          show: false,
        });
        if (showSuccessMessage) {
          Alert.success(response.data.msg);
        }
        resolve(response.data);
      })
      .catch((error) => {
        clearTimeout(showLoadingTimout);
        Dispatch({
          type: "show_loader",
          show: false,
        });
        if (showErrorMessage) {
          if (error.response && error.response.data) {
            if (error.response.data.errors) {
              Alert.html(
                "Oops",
                error.response.data.errors.join("<br />"),
                "error"
              );
            } else {
              Alert.error(error.response.data.msg);
            }
          }
        }
        resolve({
          error: error.response ? error.response.data : "timeout",
        });
      });
  });
};

export default function getPayload() {
  const token = localStorage.getItem("crm_saude_token");
  if (!token) {
    return {};
  }

  return JSON.parse(atob(token.split(".")[1]));
}
