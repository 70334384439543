import React, { useRef, useState, useEffect } from "react";
import InputMask from "../../components/InputMask";
import { Form } from "@unform/web";
import { Utils } from "../../utils";
import { request } from "../../services/request";
import { lifeApiRequest } from "../../services/lifeApiRequest";
import UpdatePatient from "./UpdatePatient";
import InitQuestionnairePatient from "./InitQuestionnairePatient";
import FullQuestionnaire from "./FullQuestionnaire";
import Result from "./Result";
import NavBar from "../../components/Navbar";
import { Col, Row } from "react-bootstrap";
import "../../assets/css/index.css";
import Person from "../../assets/img/businessman.png";
import callsfake from "../../assets/img/callsFake.png";
import { ImPhoneHangUp } from "react-icons/im";
import Alert from "../../components/Alert";
import { addDays, differenceInYears, format, parse, parseISO } from "date-fns";
export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export default function AttendanceComponent() {
  const searchPatientForm = useRef(null);
  const [showUpdatePatientForm, setShowUpdatePatientForm] = useState(false);
  const [initQuestionnairePatient, setInitQuestionnairePatient] =
    useState(false);
  const [patientData, setPatientData] = useState();
  const [answersInitQuestionnaire, setAnswersInitQuestionnaire] = useState([]);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState();
  const [diagnosisId, setDiagnosisId] = useState();
  const [cpf, setCpf] = useState();
  const [group, setGroup] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [patientApi, setPatientApi] = useState();

  const handleSearchCpf = async (data) => {
    setPatientData(null);
    data = Utils.changeEmptyValueToNull(data);
    if (!data.cpf) {
      Alert.error("Preencha o CPF");
      return;
    }

    const cpfSize = data.cpf.replace(/[^\d]/g, "");
    if (cpfSize.length < 11) {
      Alert.error("O CPF deve conter 11 dígitos");
      return;
    }
    data.cpf = data.cpf.split(".").join("").split("-").join("");
    const res = await lifeApiRequest({
      method: "GET",
      path: `contracts/application/${data.cpf}`,
      showSuccessMessage: false,
      showErrorMessage: false,
    });

    if (res.errors) {
      return Alert.error("CPF não cadastrado");
    }

    if (res.hasOwnProperty("active") && !res.active) {
      return Alert.error(`
      
          ${res.contracts.map((contract) => {
            return `<p>Cliente ${contract.clientName}.</br>
            Produto ${contract.productId}.</br>
            Contrato cancelado.</p>
            `;
          })}
        
        `);
    }

    setCpf(data.cpf);
    setGroup(res.productId);
    setCreatedAt(res.createdAt);

    if (res.birthdate) {
      let birthdaySplit = res.birthdate.split("-");
      res.birthdate = `${birthdaySplit[2]}/${birthdaySplit[1]}/${birthdaySplit[0]}`;
    }

    setPatientData(res);
    if (!res.name || !res.birthdate || !res.sex || !res.cpf) {
      setShowUpdatePatientForm(true);
    } else {
      //setInitQuestionnairePatient(true);
    }
  };

  const UpdatePatientCallback = (patientData) => {
    //setInitQuestionnairePatient(true);
    setShowUpdatePatientForm(false);
    handleSearchCpf({ cpf: patientData });
  };

  const goToQuestions = (answers) => {
    setAnswersInitQuestionnaire(answers);
    setShowQuestionnaire(true);
    setInitQuestionnairePatient(false);
  };

  const resultCallback = (value, diagnosisIdRes) => {
    setResult(value);
    setDiagnosisId(diagnosisIdRes);
    setShowQuestionnaire(false);
    setShowUpdatePatientForm(false);
    setInitQuestionnairePatient(false);
    setShowResult(true);
  };

  const callbackSaveReccomendation = () => {
    window.location.reload();
  };
  const getCheckupDate = (getCheckupDate) => {
    if (getCheckupDate) {
      return format(addDays(parseISO(getCheckupDate), 90), "dd/MM/yyyy");
    }
    return null;
  };

  const getAge = () => {
    if (!patientData.birthdate) {
      return null;
    }
    let birthdate = patientData.birthdate.split("/");
    let now = new Date();

    let age = parseInt(now.getFullYear()) - parseInt(birthdate[2]);
    return age;
  };

  return (
    <>
      <NavBar />
      <div id="main-row">
        <Col md={8}>
          {!showUpdatePatientForm &&
            !initQuestionnairePatient &&
            !showQuestionnaire &&
            !showResult && (
              <Form ref={searchPatientForm} onSubmit={handleSearchCpf}>
                <img className="logo-person" src={Person} alt="Logo" />
                <div>
                  <Row>
                    <h5 className="text-cpf">Pesquisar CPF</h5>
                  </Row>
                  <Row>
                    <InputMask
                      className="form-control input-cpf"
                      type="text"
                      id="cpf"
                      name="cpf"
                      mask={"999.999.999-99"}
                      placeholder="CPF"
                    />
                  </Row>
                  <Row>
                    <button
                      type="submit"
                      className="button button-large button-cpf"
                    >
                      Buscar
                    </button>
                  </Row>
                </div>
              </Form>
            )}
          {showUpdatePatientForm && (
            <UpdatePatient
              patientData={patientData}
              updatePatient={UpdatePatientCallback}
            />
          )}
          {initQuestionnairePatient && (
            <InitQuestionnairePatient
              patient={patientData}
              age={patientData.birthdate && getAge()}
              goToQuestionsCallBack={goToQuestions}
            />
          )}

          {showQuestionnaire && (
            <FullQuestionnaire
              patient={patientData}
              initAnswers={answersInitQuestionnaire}
              resultCallback={resultCallback}
            />
          )}
          {showResult && (
            <Result
              diagnosisId={diagnosisId}
              patient={patientData}
              result={result}
              callbackSaveReccomendation={callbackSaveReccomendation}
            />
          )}
        </Col>
        <Col className="side-bar" md={4}>
          {patientData && (
            <div>
              <Row>
                <Col md="12" className="col-info-patient">
                  <br />
                  {patientData.contracts &&
                    patientData.contracts.map((contract) => {
                      return (
                        <div key={contract._id}>
                          {contract.productId && (
                            <>
                              <h5 className="patient-text-sidebar">
                                Cliente - {contract.clientName}
                              </h5>
                              <p>Código de Produto: {contract.productId}</p>
                            </>
                          )}
                          {contract.productId === "1DM" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                              <p>Desconto em Exame</p>
                              <p>
                                Checkup Médico <br />
                                {contract.createdAt && (
                                  <>
                                    Prazo de Carência:{" "}
                                    {getCheckupDate(contract.createdAt)}
                                  </>
                                )}
                              </p>
                            </>
                          )}
                          {contract.productId === "2OS" && (
                            <p>Orientação a Saúde</p>
                          )}
                          {contract.productId === "3CK" && (
                            <p>
                              Checkup Médico <br />
                              {contract.createdAt && (
                                <>
                                  Prazo de Carência:{" "}
                                  {getCheckupDate(contract.createdAt)}
                                </>
                              )}
                            </p>
                          )}
                          {contract.productId === "5TM" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                            </>
                          )}
                          {contract.productId === "11CK" && (
                            <p>
                              Checkup Médico <br />
                              {contract.checkupCreatedAt && (
                                <>
                                  Prazo de Carência:{" "}
                                  {getCheckupDate(contract.checkupCreatedAt)}
                                </>
                              )}
                            </p>
                          )}
                          {contract.productId === "8CC" && (
                            <>
                              <p>Telemedicina</p>
                              <p>Desconto em Exame</p>
                            </>
                          )}
                          {contract.productId === "12CL1" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                            </>
                          )}
                          {contract.productId === "12CL2" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                            </>
                          )}
                          {contract.productId === "13ALL" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                              <p>Orientação Piscológica</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                              <p>Checkup</p>
                            </>
                          )}
                          {contract.productId === "13TDC" && (
                            <>
                              <p>Orientação a Saúde</p>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                              <p>Checkup</p>
                            </>
                          )}
                          {contract.productId === "14PSC" && (
                            <>
                              <p>Orientação Piscológica</p>
                            </>
                          )}
                          {contract.productId === "15BM1" && (
                            <>
                              <p>Bem Mais Clube de Benefícios</p>
                              <p>Plano Individual</p>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                            </>
                          )}
                          {contract.productId === "15BM2" && (
                            <>
                              <p>Bem Mais Clube de Benefícios</p>
                              <p>Plano Família</p>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                            </>
                          )}
                          {contract.productId === "16TEM" && (
                            <>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                            </>
                          )}
                          {contract.productId === "17TPC" && (
                            <>
                              <p>Telemedicina</p>
                              <p>Orientação Piscológica</p>
                              <p>Desconto em Medicamentos</p>
                              <p>Checkup</p>
                              <p>Assistência Inventário</p> 
                            </>
                          )}
                          {contract.productId === "18DAO" && (
                            <>
                              <p>Telemedicina</p>
                              <p>Orientação Piscológica</p>
                            </>
                          )}
                          {contract.productId === "4CS" && (
                            <>
                              <p>Desconto em Exames</p>
                            </>
                          )}
                          {contract.productId === "6DPS" && (
                            <>
                              <p>DPS</p>
                            </>
                          )}
                          {contract.productId === "7JR" && (
                            <>
                              <p>Orientação a Saúde </p>
                            </>
                          )}
                          {contract.productId === "10DC" && (
                            <>
                              <p>Descontos de Medicamentos</p>
                              <p>Checkup</p>
                            </>
                          )}
                          {contract.productId === "19TMD" && (
                            <>
                              <p>Telemedicina</p>
                              <p>Desconto em Exames</p>
                              <p>Desconto em Medicamentos</p>
                              <p>Diária por Incapacidade Temporária (DIT)</p>
                            </>
                          )}
                          {contract.productId === "custom" && (
                            <>
                              <p>Vida encontrada, mas produto não mapeado.</p>
                              <p>Entrar em contato com equipe de TI.</p>
                            </>
                          )}
                        </div>
                      );
                    })}
                </Col>
                <Col md="6" className="col-info-patient">
                  <h5 className="patient-text-sidebar">
                    Paciente <br /> {cpfMask(patientData.cpf)}
                  </h5>

                  <span>
                    {patientData.sex &&
                      (patientData.sex === "M"
                        ? "Homem, "
                        : patientData.sex === "F"
                        ? "Mulher, "
                        : "")}
                  </span>
                  {patientData.birthdate && <span>{getAge()} anos. </span>}

                  <span>{patientData.name}</span>
                </Col>
                <Col style={{ alignSelf: "center" }} md="5">
                  <button
                    align="center"
                    className="button button-off"
                    onClick={(e) => window.location.reload()}
                  >
                    {" "}
                    <ImPhoneHangUp color="white" size="2rem" />
                  </button>
                </Col>
              </Row>
              <hr />
            </div>
          )}
          {/* <img  style={{marginLeft:"1rem", marginTop:"1rem"}} src={callsfake} alt="Logo" /> */}
        </Col>
      </div>
    </>
  );
}
