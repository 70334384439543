import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import Radio from "../../components/RadioAddPacient";
import { Form } from "@unform/web";
import { Utils } from "../../utils";
import { lifeApiRequest } from "../../services/lifeApiRequest";
import InputMask from "../../components/InputMask";
import "../../assets/css/createPatient.css";

export default function CreatePatient({ patientData, updatePatient }) {
  const createPatientForm = useRef(null);

  const handleCreatePatient = async () => {
    let data = createPatientForm.current.getData();
    data = Utils.changeEmptyValueToNull(data);

    let birthday = null;
    if (data.birthday) {
      let birthdaySplit = data.birthday.split("/");
      birthday = `${birthdaySplit[2]}-${birthdaySplit[1]}-${birthdaySplit[0]}`;
    }

    if (data.cpf) {
      data.cpf = data.cpf.split(".").join("");
      data.cpf = data.cpf.replace("-", "");
    }

    const res = await lifeApiRequest({
      method: "POST",
      path: `lives`,
      showSuccessMessage: false,
      data: {
        name: data.name,
        clientId: patientData.clientId,
        cpf: data.cpf,
        birthdate: birthday,
        cellphone: data.cellphone.replace(/\D/g, ""),
        sex: data.sex === "male" ? "M" : data.sex === "female" ? "F" : null,
      },
    });

    if (!res.error) {
      updatePatient(data.cpf);
    }
  };

  return (
    <>
      {patientData && (
        <Form ref={createPatientForm} onSubmit={handleCreatePatient}>
          <div>
            <h5 className="text-add-patient">Atualizar Paciente</h5>

            <Input
              className="form-control input-create-patient"
              placeholder="Nome"
              type="text"
              id="name"
              name="name"
              defaultValue={patientData.name}
            />

            <InputMask
              className="form-control input-create-patient"
              placeholder="Data de nascimento"
              type="text"
              id="birthday"
              name="birthday"
              mask={"99/99/9999"}
              defaultValue={patientData.birthdate}
            />

            <InputMask
              className="form-control input-create-patient"
              placeholder="CPF"
              type="text"
              id="cpf"
              name="cpf"
              defaultValue={patientData.cpf}
              mask={"999.999.999-99"}
            />

            <InputMask
              className="form-control input-create-patient"
              placeholder="Celular"
              type="text"
              id="cellphone"
              name="cellphone"
              defaultValue={patientData.cellphone}
              mask={"(99) 9 9999 9999"}
            />

            <div className="row">
              <div className="checkbox-sex">
                <Radio
                  defaultValue={patientData.sex}
                  name="sex"
                  options={[
                    { id: "female", label: "Mulher" },
                    { id: "male", label: "Homem" },
                  ]}
                />
              </div>
            </div>
            <button className="button button-large button-cpf" type="submit">
              Atualizar
            </button>
          </div>
        </Form>
      )}
    </>
  );
}
