export const Utils = {
  changeEmptyValueToNull(obj) {
    for(var key in obj) {
      if( null === obj[key] || '' === obj[key]) delete obj[key];
      if ( typeof obj[key] === 'object' ) this.changeEmptyValueToNull(obj[key]);
    }

    return obj
  },
  async sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}