import React from "react"
import {Col,Row} from "react-bootstrap"
import "../assets/css/footerLogin.css"

export default function footerLogin(){
    return(
        <div className="row-main position-button">
            <Col md={2}>
                <a className="text-center" href="#" style={{color:"red"}}>© Pessoalize 2020</a>
            </Col>
            <Col md={2}>
                <a className="text-center" href="#">Reportar problema</a>
            </Col>
        </div>
    )
}